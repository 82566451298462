import env from '../env';
import ls from "local-storage";
import ReactDOM from 'react-dom';
import React from 'react';
import axios from "axios";
import Checkout from './checkout';
import {
    isBrowser,
    isTablet,
    isMobile
  } from "react-device-detect";
  import postscribe from 'postscribe';
ls.backend(sessionStorage);
const LLAPIPROCESS = env.LLAPIPROCESS;

//self component

const UnmountApp = async (elementId) =>{
    
    if(document.getElementById(elementId)){
        document.getElementById(elementId).remove();
    }

    return await ReactDOM.unmountComponentAtNode(document.getElementById('pixel'));
}

const App = async (script, elementId) => {
   
    var isHtml = /<\/?[a-z][\s\S]*>/i.test(script) 

    const renderScript =()=>{
        if(!isHtml && !document.getElementById(elementId)){
            try{
                let scriptTag = document.createElement('script');
                scriptTag.innerHTML = `try{`+script+`}catch(e){console.log("error",e)}`;
                scriptTag.type = "text/javascript";
                scriptTag.id = elementId;
                scriptTag.onerror = (error)=>{
                    alert(error);
                }
                document.getElementsByTagName("head")[0].appendChild(scriptTag);
            }catch(e){
                console.log("Error Exception", e);
            }  
        }else{
            let divTag = document.createElement('div');
            divTag.id = elementId;
            document.getElementsByTagName("body")[0].appendChild(divTag);
            postscribe("#"+elementId, script);
        }
    }
    
    

    await ReactDOM.render(<React.Fragment>
                            {renderScript()}
                    </React.Fragment>, document.getElementById('pixel'))

  };
//End


export default class commonHooks{
    constructor(){
        this.allPixelConfig =[];
        this.pixelConfig = {};
        this.currentRoute = "";
        this.requestConfig = {};
        this.actionName = "";
        this.postResponse = {};
        this.requestData = {};
        this.reRenderPixel = true
        

        this.actionSet = {
            "routes":{
                "/":"/prospect",
                "/payment":"/payment",
                "/thank-you":"/thank-you"
            },
            "priorevents":{
                "create-prospect":"prospect",
                "process-order":"payment",
                "process-onepage-checkout":"payment",
                "back-sync-paypal-order":"payment",
                "process-order-total":"coupon-applied"
            },
            "postevents":{
                "create-prospect":"prospect",
                "process-order":"payment",
                "process-onepage-checkout":"payment",
                "back-sync-paypal-order":"payment",
                "process-order-total" : "coupon-applied"
            }
        }


        this.affilateArray ={
            "pixel_affiliate_afid":"afid",
            "pixel_affiliate_affid":"affid",
            "pixel_subaffiliate_sid":"sid",
            "pixel_subaffiliate_c1":"c1",
            "pixel_subaffiliate_c2":"c2",
            "pixel_subaffiliate_c3":"c3",
            "pixel_subaffiliate_aid":"aid",
            "pixel_subaffiliate_opt":"opt",
            "pixel_subaffiliate_click_id":"click_id"
        }

        this.tokenData ={
            "[_PROSPECT_ID_]":"",
            "[_ORDER_ID_]":"",
            "[_TRANSACTION_ID_]":"",
            "[_CUSTOMER_ID_]":"",
            "[_ORDER_TOTAL_]":"",
            "[_PROMO_CODE_]":"",
            "[_DISCOUNT_PRICE_]":"",
            "[_STORE_NAME_]":"",
            "[_SHIPPING_PRICE_]":"",
            "[_TAX_PRICE_]":"",
            "[_CART_PRODUCTS_]":"",
            "[_AFID_]":"",
            "[_AFFID_]":"",
            "[_SID_]":"",
            "[_AID_]":"",
            "[_C1_]":"",
            "[_C2_]":"",
            "[_C3_]":"",
            "[_C4_]":"",
            "[_C5_]":"",
            "[_C6_]":"",
            "[_CLICK_ID_]":"",
            "[_FIRST_NAME_]":"",
            "[_LAST_NAME_]":"",
            "[_EMAIL_]":"",
            "[_PHONE_]":"",
            "[_SHIPPING_CITY_]":"",
            "[_SHIPPING_STATE_]":"",
            "[_SHIPPING_COUNTRY_]":"",
            "[_CRM_RESPONSE_]":"",
            "[_SESSION_TOKEN_]":""
        };
                        
    }
    /*
    Notes:
        1. For paypal the post pixel will be call on "paypal-success" page, Need validation on that 
        2. process-order-total for coupon code validation, need validation so only call once 
        3. process-upsell-order for the upsell process but it will be fire on the basis of selected upsell-id in configuration
    */
    async routeHooks(){
        try{    
            //route change
            await this.getPixelconfig();

            if(!this.allPixelConfig)
                return false;

            
            if(window.location.pathname !== this.currentRoute){
                this.currentRoute = window.location.pathname;
                // check if url is not redirected when it contains /app/ then behave it as /prospect(base url)
                if (window.location.pathname.includes('/app/')){
                    this.currentRoute = '/';
                }

                for(var i=0;i<this.allPixelConfig.length;i++){
                    this.pixelConfig = this.allPixelConfig[i];
                    let post = this.pixelConfig.pixel_page_triggers["postevents"];
                    let prior = this.pixelConfig && this.pixelConfig.pixel_page_triggers["priorevents"];
                    let upsells = this.pixelConfig && this.pixelConfig.pixel_page_triggers["upsells"];
                    let routes = this.pixelConfig && this.pixelConfig.pixel_page_triggers["routes"];
                    let generic = this.pixelConfig && this.pixelConfig.pixel_page_triggers["generic"];

                    let action  = this.actionSet["routes"][this.currentRoute];

                    //exception route
                        let exceptionRoute = this.pixelConfig &&  this.pixelConfig.pixel_page_exceptions && this.pixelConfig.pixel_page_exceptions["routes"] ;
                        if(exceptionRoute && exceptionRoute.indexOf(action)>-1){
                            await UnmountApp(this.pixelConfig._id.$oid); 
                            continue;
                        }
                    //End of Exception


                    //1.1 logic to set pixel if it has no any proper event
                    if(Array.isArray(post) && Array.isArray(prior) && Array.isArray(upsells) && Array.isArray(routes) && Array.isArray(generic) && post.length<1 && prior.length<1 && upsells.length<1 && routes.length<1 && generic.length<1){
                        this.reRenderPixel = false; 
                        await this.triggerPixel();
                        continue;
                    }
                    //1.1 end

                    
                    if(!routes) continue; 
                    
                    if(routes && Array.isArray(routes) && routes.length<1) continue;

                    if(routes.indexOf(action)<0) continue;
                    
                    await this.triggerPixel(); 
                }
                
            }
        
        }catch(e){
            console.log("Error",e);
        }
    }

    async priorHooks(requestConfig){
        try{
            this.getPixelconfig();
            this.requestConfig = requestConfig
            this.getActionName();
            this.requestData = this.requestConfig.data;
            
            //implement logic for process-upsell-order
            if(this.actionName === 'process-upsell-order'){
                this.upsellHooks();
                return true;
            }

            //for coupon validation
            if(this.actionName === 'process-order-total' && this.requestData && this.requestData.promo_code===""){
                return true;
            }

            for(var i=0;i<this.allPixelConfig.length;i++){
                this.pixelConfig = this.allPixelConfig[i];
                let prior = this.pixelConfig && this.pixelConfig.pixel_page_triggers["priorevents"];
                if(prior && Array.isArray(prior) && prior.length<1) continue;
                let action  = this.actionSet["priorevents"][this.actionName];
                let exceptionPrior = this.pixelConfig && this.pixelConfig.pixel_page_exceptions["priorevents"];

                if(exceptionPrior && exceptionPrior.indexOf(action)>-1){  
                    await UnmountApp(this.pixelConfig._id.$oid); 
                    continue;
                }
                
                if(prior.indexOf(action)<0) continue;
                
                await this.triggerPixel();
            }
             
 
        }catch(e){
            console.log("priorHooks", e);
        }
    }

    async postHooks(responseConfig){
        try{
            this.postResponse = responseConfig;
            let prepaid_match  = responseConfig && responseConfig.data && responseConfig.data.responseData && responseConfig.data.responseData.prepaid_match ? responseConfig.data.responseData.prepaid_match: 0;
            this.getPixelconfig();
            this.requestConfig = responseConfig.config;
            this.getActionName();
            this.requestData = this.requestConfig.data ? JSON.parse(this.requestConfig.data) : {};
            //for coupon validation
            
            if(this.actionName === 'process-order-total' && this.requestData && this.requestData.promo_code === ""){
                return true;
            }

            for(var i=0;i<this.allPixelConfig.length;i++){
                
                this.pixelConfig = this.allPixelConfig[i];
                let post = this.pixelConfig && this.pixelConfig.pixel_page_triggers["postevents"];
                if(post && Array.isArray(post) && post.length<1) continue;
                let action  = this.actionSet["postevents"][this.actionName];
                let postException = this.pixelConfig && this.pixelConfig.pixel_page_exceptions["postevents"];
                
                //logic for prepaid_match
                if(this.pixelConfig.pixel_enable_prepaid_transactions === true  && prepaid_match !== 0){
                    await this.triggerPixel();
                    continue;
                }
                //End of prepaid_match
                
                if(postException && postException.indexOf(action)>-1) {  
                    await UnmountApp(this.pixelConfig._id.$oid); 
                    continue;
                }
                
                if(post.indexOf(action)<0) continue;
                
                await this.triggerPixel();
                
            }
        }catch(e){
            console.log("postHooks",e);
        }
    }

    async upsellHooks(){
        try{
            
            if(!this.requestConfig)
                return false;
            
            let data = (this.requestConfig.data);
            for(var i=0;i<this.allPixelConfig.length;i++){
                this.pixelConfig = this.allPixelConfig[i];
                let upsells = this.pixelConfig && this.pixelConfig.pixel_page_triggers["upsells"];
                let upsellsException = this.pixelConfig && this.pixelConfig.pixel_page_exceptions["upsells"];

                if(upsellsException && upsellsException.indexOf(data.upsell_id)>-1) {  
                    await UnmountApp(this.pixelConfig._id.$oid); 
                    continue;
                }
            
                if(upsells.indexOf(data.upsell_id)<0) continue; 
                
                await this.triggerPixel();
            }
            
        }catch(e){
            console.log("upsellHooks",e);
        }
    }


    async cardAbandoned(){ //card abandan logic change
        try{
           
            this.getPixelconfig();
            
            var processConfig = [];
            
            for(var i=0;i<this.allPixelConfig.length;i++){
                if(this.allPixelConfig[i]['pixel_page_exceptions'] && "generic" in this.allPixelConfig[i]['pixel_page_exceptions'] && this.allPixelConfig[i]['pixel_page_exceptions']['generic'].indexOf("cart-abandoned")>-1 ){ 
                    await UnmountApp(this.allPixelConfig[i]._id.$oid); 
                    continue;
                };



                //for all
                let routes = this.allPixelConfig[i]['pixel_page_triggers']["routes"];
                let post = this.allPixelConfig[i]['pixel_page_triggers']["postevents"];
                let prior = this.allPixelConfig[i]['pixel_page_triggers']["priorevents"];
                let upsells = this.allPixelConfig[i]['pixel_page_triggers']["upsells"];
                let generic = this.allPixelConfig[i]['pixel_page_triggers']['generic']

                if(Array.isArray(post) && Array.isArray(prior) && Array.isArray(upsells) && post.length<1 && prior.length<1 && upsells.length<1 && Array.isArray(routes) && routes.length<1 && Array.isArray(generic) && generic.length<1){
                    this.reRenderPixel = false; 
                    processConfig.push(this.allPixelConfig[i]); 
                    continue;
                }

                if(this.allPixelConfig[i]['pixel_page_triggers'] && "generic" in this.allPixelConfig[i]['pixel_page_triggers'] && this.allPixelConfig[i]['pixel_page_triggers']['generic'].indexOf("cart-abandoned")>-1){
                    processConfig.push(this.allPixelConfig[i]);
                }
            }

            if(processConfig.length < 1)
                return false;
            
            
            var self = this;
            window.addEventListener('beforeunload',async function (e) {
                e.preventDefault();
                e.returnValue = '';
                await self.cardAbandonedEvent(processConfig);
            });
            
        }catch(e){
            console.log("Error", e);
        }
    }

    async cardAbandonedEvent(processConfig){
        try{
            if(!processConfig)
                return false;

            for(var i=0;i<processConfig.length;i++){
                this.pixelConfig = processConfig[i];
                await this.triggerPixel();
            }
        }catch(e){
            console.log("Error", e);
        }
    }
    

    async getPixelconfig(){
        try{
            let cart = await Checkout.getCartDataAtLocal();
            let allPixelConfigArr = [];
            if(cart && cart.cartInfo && cart.cartInfo.pixelConfig)
            {
                cart.cartInfo.pixelConfig.forEach(function (conf) {
                    allPixelConfigArr.push({
                        ...conf
                    });
                });
            }
            this.allPixelConfig = allPixelConfigArr;
        }catch(e){
            console.log("Error",e);
        }
    }
    //-------------- for internal process --------//
    
    getActionName(){
        try{
            let url = this.requestConfig.url;
            this.actionName = url.replace(LLAPIPROCESS, "");
        }catch(e){
            //error
        }
    }

   async triggerPixel(){
        try{
            //validate the device
            let deviceType = await this.detectDevice();
            
            if(deviceType !== true){
                return false;
            }

            let validateAff = await this.validateAffilate();
            
            if(validateAff !== true){
                return false;
            }

            await this.manageTokenAndThereValues();
            await this.updateTokenToPixel();
            
            if(this.pixelConfig && (this.pixelConfig.pixel_type === 'Script') )
                return await this.fireDomPixel();
            
            
            if(this.pixelConfig && (this.pixelConfig.pixel_type === 'Postback URL') )
                await this.firePostBackPixel()

            if(this.pixelConfig && this.pixelConfig.pixel_type === 'Both' ){
                await this.fireDomPixel();
                return await this.firePostBackPixel()
            }

        }catch(e){
            console.log(e);
        }
    }

    detectDevice(){
        let status = false;
        try{

            if(this.pixelConfig && this.pixelConfig.pixel_selected_devices && this.pixelConfig.pixel_selected_devices.length > 0){
                if( (isBrowser && this.pixelConfig.pixel_selected_devices.indexOf("Desktop")>-1) ){
                    status =  true;
                } 
                
                if( (isTablet && this.pixelConfig.pixel_selected_devices.indexOf("Tablet")>-1 ) ){
                    status =  true;
                }
                
                if( (isMobile && this.pixelConfig.pixel_selected_devices.indexOf("Mobile")>-1 ) ){
                    status =  true;
                }

            }else{
                status = true;
            }
            

            return status;
        }catch(e){
            
            return status;
        }
    }

    validateAffilate(){
        try{
            var affiliate = ls.get("affiliate");
            var dbAfflink = "";
            var urlAfflink = "";
            var pixelConfig = this.pixelConfig;
            var affilateArray = this.affilateArray;

            for(var key in this.affilateArray){

                    if(key in pixelConfig && pixelConfig[key]!==""){
                        dbAfflink = dbAfflink + affilateArray[key]+"="+pixelConfig[key]+"&"
                        
                        if(affiliate && Object.keys(affiliate).length>0 && affilateArray[key] in affiliate && affiliate[affilateArray[key]]){
                            urlAfflink = urlAfflink + affilateArray[key]+"="+affiliate[affilateArray[key]] + "&"
                        }
                    }
            }
           
            return (dbAfflink === urlAfflink ? true : false);
        }catch(e){
            console.log("Error",e);
        }
    }

    async fireDomPixel(){
        var self = this;
        return  new Promise(async function(resolve, reject) {
           
            try{ 
                if(!self.pixelConfig)
                    resolve(false)
    
                let el = document.getElementById('pixel');
    
                if(!el) resolve(false)
                
                let elementId =  self.pixelConfig._id.$oid; 
                if(self.reRenderPixel)  await UnmountApp(elementId);
                
                let pixel_script = self.pixelConfig.pixel_script;
                setTimeout(function(){App(pixel_script, elementId); resolve(true) }, 1500);
    
            }catch(e){
                console.log(e,"Err");
                resolve(false);
            }
        },this);
        
    }

    async firePostBackPixel(){
        try{
            
            if(this.pixelConfig){
                return await axios.post(LLAPIPROCESS+'post-back', {
                    'X-LL-Hash' : Checkout.getllHashAtLocal(),
                    'postback_url': this.pixelConfig.pixel_postback_url
                  });
            }
        }catch(e){
            console.log("firePostBackPixel", e);
            return true;
        }
    }

    async manageTokenAndThereValues(){
        try{
            let cartInfo = await Checkout.getCartDataAtLocal();
            cartInfo = cartInfo && cartInfo.cartInfo;
            let storeName = cartInfo && cartInfo.shopDetails && cartInfo.shopDetails.name ? cartInfo.shopDetails.name :'';
            let customerInfo = ls.get("customerInfo");
            let shippingId = customerInfo && customerInfo.shipping ? customerInfo.shipping: "";
            let shippingData =  cartInfo && cartInfo.shippingMethods && Object.keys(cartInfo.shippingMethods).length ? Object.values(cartInfo.shippingMethods).find(elm=>elm.shipping_method_crm_id === shippingId) : {};
            let isFreeShipping = cartInfo && cartInfo.token_based_user_free_shipping ? true : false;
            let tax = ls.get("tax");
            let taxAmount = tax && tax.tax && tax.tax.total ? tax.tax.total : 0
            let affiliate = ls.get("affiliate");

            let prospectId = this.postResponse && this.postResponse.data && this.postResponse.data.responseData && this.postResponse.data.responseData.prospectId ? this.postResponse.data.responseData.prospectId : ls.get("prospectId");
            let orderId = this.postResponse && this.postResponse.data && this.postResponse.data.responseData && this.postResponse.data.responseData.order_id ? this.postResponse.data.responseData.order_id :ls.get("orderId");
            let coupon = this.requestData && this.requestData.promo_code ? this.requestData.promo_code : ls.get("coupon");
            let discountData = this.postResponse && this.postResponse.data && this.postResponse.data.responseData &&  this.postResponse.data.responseData.data && this.postResponse.data.responseData.data.discounts  && this.postResponse.data.responseData.data.discounts.length>0 ? this.postResponse.data.responseData.data.discounts.find(ele=>ele.code.toString().toUpperCase() === coupon.toString().toUpperCase()) : {}
            let discount = discountData && discountData.valid && discountData.total ? discountData.total.toString().replace(/,/g, '') : ls.get("discount");
            this.tokenData ={
                "[_PROSPECT_ID_]": prospectId ? prospectId :"",
                "[_ORDER_ID_]": orderId ? orderId : "",
                "[_TRANSACTION_ID_]":orderId ? orderId : "",
                "[_CUSTOMER_ID_]":prospectId ? prospectId :"",
                "[_ORDER_TOTAL_]":ls.get("ordertotal") ? ls.get("ordertotal") : "",
                "[_PROMO_CODE_]":coupon ? coupon : "",
                "[_DISCOUNT_PRICE_]":discount ? discount : "",
                "[_STORE_NAME_]":storeName,
                "[_SHIPPING_PRICE_]":isFreeShipping ? 0.00 : (shippingData && shippingData.initial_amount ? shippingData.initial_amount :0.00),
                "[_TAX_PRICE_]":taxAmount,
                "[_CART_PRODUCTS_]":"",
                "[_AFID_]":(affiliate && affiliate.afid ? affiliate.afid :""),
                "[_AFFID_]":(affiliate && affiliate.affid ? affiliate.affid :""),
                "[_SID_]":(affiliate && affiliate.sid ? affiliate.sid :""),
                "[_AID_]":(affiliate && affiliate.aid ? affiliate.aid :""),
                "[_C1_]":(affiliate && affiliate.c1 ? affiliate.c1 :""),
                "[_C2_]":(affiliate && affiliate.c2 ? affiliate.c2 :""),
                "[_C3_]":(affiliate && affiliate.c3 ? affiliate.c3 :""),
                "[_C4_]":(affiliate && affiliate.c4 ? affiliate.c4 :""),
                "[_C5_]":(affiliate && affiliate.c5 ? affiliate.c5 :""),
                "[_C6_]":(affiliate && affiliate.c6 ? affiliate.c6 :""),
                "[_CLICK_ID_]":(affiliate && affiliate.click_id ? affiliate.click_id :""),
                "[_FIRST_NAME_]":(customerInfo && customerInfo.firstName ? customerInfo.firstName :""),
                "[_LAST_NAME_]":(customerInfo && customerInfo.lastName ? customerInfo.lastName :""),
                "[_EMAIL_]":(customerInfo && customerInfo.email ? customerInfo.email :""),
                "[_PHONE_]":(customerInfo && customerInfo.phone ? customerInfo.phone :""),
                "[_SHIPPING_CITY_]":(customerInfo && customerInfo.city ? customerInfo.city :""),
                "[_SHIPPING_STATE_]":(customerInfo && customerInfo.state ? customerInfo.state :""),
                "[_SHIPPING_COUNTRY_]":(customerInfo && customerInfo.country ? customerInfo.country :""),
                "[_CRM_RESPONSE_]":"",
                "[_SESSION_TOKEN_]":ls.get("session") ? ls.get("session") :""
            };
        }catch(e){
            console.log(e);
        }
    }

    updateTokenToPixel(){
        try{
            
            for(var key in this.tokenData){
                
                if(this.pixelConfig.pixel_script){
                    this.pixelConfig.pixel_script =  this.pixelConfig.pixel_script.split(key).join(this.tokenData[key]);
                }

                if(this.pixelConfig.pixel_postback_url){
                    this.pixelConfig.pixel_postback_url =  this.pixelConfig.pixel_postback_url.split(key).join(this.tokenData[key]);
                }
                
            }
        }catch(e){
            console.log(e);
        }
    }

    setFeviconTocheckout(feviconUrl){
        try{
            if(feviconUrl === false){
                return false;
            }

            // Old fevicon removed if exists
            var oldFevicon = document.getElementById('fevicon');
            if(oldFevicon !== null){
                oldFevicon.remove();
            }

            var feviconTag = document.createElement('link');
                feviconTag.id = 'fevicon';
                feviconTag.rel = 'icon';
                feviconTag.href = feviconUrl;
            
            document.head.appendChild(feviconTag);    
        }catch(e){
            console.log(e);
        }
    }



}


