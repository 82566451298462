import swal from 'sweetalert';
import ls from "local-storage";
import Checkout from "./checkout"
class payPalWindow{

   async processPaypal(param){

    var promise = new Promise(async (resolve, reject) => {
        
        try{
            

            swal("We are processing your payment",{
                closeOnClickOutside: false,
                closeOnEsc:false,
                cancel: false,
                buttons: false,
                icon:"info",
                
              });
  
  
              //create the form
              var form = document.createElement("form");
              form.setAttribute("method", "post");
              form.setAttribute("action", param['url']);
              form.setAttribute("target", "view");
              //End of create the form 
              param['products'] = JSON.stringify(param['products']);
              param['oneClickUpsells'] = JSON.stringify(param['oneClickUpsells']);
  
              for(var k in param){
                  let hiddenField = document.createElement("input"); 
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", k);
                    hiddenField.setAttribute("value", param[k]);
                    form.appendChild(hiddenField);
                    document.body.appendChild(form);
  
              }
              //close the window swal.close()
              const w = 750;
              const h = 600;
              const url = '';
              const title = "view";
              const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
              const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
  
              const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
              const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
  
              const systemZoom = width / window.screen.availWidth;
              const left = (width - w) / 2 / systemZoom + dualScreenLeft
              const top = (height - h) / 2 / systemZoom + dualScreenTop
              this.modalWindow = window.open(url, title, 
                `
                scrollbars=yes,
                width=${w / systemZoom}, 
                height=${h / systemZoom}, 
                top=${top}, 
                left=${left}
                `
              )
              this.modalWindow.opener = null;
              this.modalWindow.blur();
              this.modalWindow.focus();
              var localWindow =this.modalWindow;
  
              
  
              await Checkout.savePaypalResponse(false);
              var interval = setInterval(async function(){
                  try{
                    
                    let paymentData = Checkout.getPaypalResponse();

                    if(paymentData && paymentData.orderId){
                      swal.close();
                      clearInterval(interval);
  
                      if(paymentData.errorFound !== 0){
                        swal(paymentData.errorMessage,{
                          icon:"error",
                        });

                        resolve(false);
                      }else{
                        resolve(true);
                      }
                      
                    }

                    if(paymentData && paymentData.error_found>0){
                        swal.close();
                        clearInterval(interval);
                        swal(paymentData.error_message,{
                            icon:"error",
                          });
                          resolve(false);
                    }

                    //for window close 
                    
                    if(localWindow.closed){
                      var paypalResponse = (this.modalWindow.paypalResopnse) ? this.modalWindow.paypalResopnse : false;
                      await Checkout.savePaypalResponse(paypalResponse);
                      swal.close();
                      clearInterval(interval);
                      if((paypalResponse && paypalResponse.errorFound > 0)){
                        resolve(false);
                      }else if(paypalResponse && paypalResponse.orderId){
                        resolve(true);
                      }else{
                        resolve(false);
                      }
                    }

                  }catch(e){
                    console.log("deve",e);
                  }
              }.bind(this),2000);
              form.submit();


              
        }catch(e){
            console.log("Error:",e);
            resolve(false);
        }

    });
    return promise;

        
    }

    async processPrivateWindow(param){
      var promise = new Promise(async (resolve, reject) => {
          try{
            swal("We are processing your payment",{
              closeOnClickOutside: false,
              closeOnEsc:false,
              cancel: false,
              buttons: false,
              icon:"info",
              
            });


            //close the window swal.close()
            const w = 750;
            const h = 600;
            const url = param.url ;
            const title = "view";
            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft
            const top = (height - h) / 2 / systemZoom + dualScreenTop
            this.modalWindow = window.open(url, title, 
              `
              scrollbars=yes,
              width=${w / systemZoom}, 
              height=${h / systemZoom}, 
              top=${top}, 
              left=${left}
              `
            )
            this.modalWindow.opener = null;
            this.modalWindow.blur();
            this.modalWindow.focus();
            var localWindow =this.modalWindow;
            Checkout.removeNonce();
            var interval = setInterval(async function(){
              try{
                
                let paymentData =await Checkout.getSquareNonce();
                
                if(paymentData){
                  swal.close();
                  clearInterval(interval);
                  resolve(true);
                }

                //for window close 
                
                if(localWindow.closed){
                  var squareResponse = (this.modalWindow.squareResponse) ? this.modalWindow.squareResponse : false;
                  ls.set("nonce",squareResponse);
                  swal.close();
                  clearInterval(interval);
                  resolve(false);
                }

              }catch(e){
                console.log("deve",e);
              }
          }.bind(this),2000);


          }catch(e){
            //
            console.log(e);
          }
      });
      return promise;
    }

    async processPrivateWindowForAmazon(){
      var promise = new Promise(async (resolve, reject) => {
          try{
            swal("We are processing your payment",{
              closeOnClickOutside: false,
              closeOnEsc:false,
              cancel: false,
              buttons: false,
              icon:"info",
              
            });


            //close the window swal.close()
            const w = 800;
            const h = 650;
            const url = window.location.origin+'/amazon-pay' ;
            const title = "Amazon Pay";
            const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
            const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

            const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
            const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

            const systemZoom = width / window.screen.availWidth;
            const left = (width - w) / 2 / systemZoom + dualScreenLeft
            const top = (height - h) / 2 / systemZoom + dualScreenTop
            this.modalWindow = window.open(url, title, 
              `
              scrollbars=yes,
              width=${w / systemZoom}, 
              height=${h / systemZoom}, 
              top=${top}, 
              left=${left}
              `
            )
            this.modalWindow.opener = null;
            this.modalWindow.blur();
            this.modalWindow.focus();
            var localWindow =this.modalWindow;
            var interval = setInterval(async function(){
              try{
                
                let paymentData = await Checkout.getAmazonPayNonce();
                
                if(paymentData &&  paymentData.processPayment){
                  swal.close();
                  clearInterval(interval);
                  resolve(true);
                }

                //for window close 
                
                if(localWindow.closed){
                  var amazonPayResponse = (this.modalWindow.amazonPayResponse) ? this.modalWindow.amazonPayResponse: false;
                  ls.set('amazonPay', amazonPayResponse);
                  swal.close();
                  clearInterval(interval);
                  if(amazonPayResponse && amazonPayResponse.processPayment){
                    resolve(true);
                  }else{
                    resolve(false);
                  }
                }

              }catch(e){
                console.log("deve",e);
              }
          }.bind(this),1000);


          }catch(e){
            //
            console.log(e);
          }
      });
      return promise;
    }
}

const payPal = new payPalWindow();
export default payPal;