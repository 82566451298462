import ls from "local-storage";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import env from '../env';
import swal from 'sweetalert';
import commonHooks from './commonHooks';
import {store} from '../store/index';
import { setLlHash } from '../store/actions/llConfigActions';
ls.backend(sessionStorage);


const LLAPIPROCESS = env.LLAPIPROCESS;


export default new (class Checkout {
  constructor() {
     toast.configure();
     let session= ls.get("session");
     if(!axios.defaults.headers.common['Authorization'] && session){
      axios.defaults.headers.common['Authorization'] = session;
      document.cookie = "PHPSESSID="+session;
     }

     this.commonHooksIns = new commonHooks();
     

    axios.interceptors.request.use( async (config) => {
          if(config.url.search('post-back')<0){
            await this.commonHooksIns.priorHooks(config);
          }

          config.data = this.getRedeemCoupon(config.data);
          
        return config;
    }, error => {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(async (response) => {
      
      if(response.config.url.search('post-back')<0){
        await this.commonHooksIns.postHooks(response);
      }  
      return response;
    }, error => {
      return Promise.reject(error);
    });
  }

  getRedeemCoupon(configData){
    try{
      let redeemCoupons = ls.get("redeemCoupons");

      if(configData && redeemCoupons){
        configData.redeemCoupons = redeemCoupons;
      }

      return configData;
    }catch(e){
      return configData;
    }
  }
 
  async getCartData(ll_hash) {
    try{
      let session = ls.get("session");
      if(!axios.defaults.headers.common['Authorization'] && session){
        axios.defaults.headers.common['Authorization'] = session;
        document.cookie = "PHPSESSID="+session;
      }
      
      var data = {};
      let url = LLAPIPROCESS+'get-ll-config'
      if(!ll_hash){
        return false;
      }
      const cartInfo = await this.getCartDataAtLocal();
      
      let products
      if(cartInfo && cartInfo.cartInfo){
        //logic change for dynamic checkout
        if(cartInfo.cartInfo.shopDetails.label_url === ll_hash || ll_hash.indexOf(cartInfo.cartInfo.shopDetails.label_url)>-1){
          products = cartInfo.cartInfo.shopDetails.products
        }else{
          products = {}
        }  
      }
      
      ls.set("ll_upsell_product","5dfcab8112ecb775efe0e7b1");
      
      data = axios
          .post(url, { 'X-LL-Hash': ll_hash, 'products' : products })
        .then((response) =>{
          if (response.status === 200) {
            ls.set("checkout_enable_tax", (response.data.cartInfo && response.data.cartInfo.checkout_enable_tax) ? response.data.cartInfo.checkout_enable_tax : false);
            let redeem_coupons = response && response.data.cartInfo && response.data.cartInfo.redeem_coupons ? response.data.cartInfo.redeem_coupons : [];
            ls.set('redeem_coupons_with_amount',redeem_coupons);
            return response.data;
          }
        })
        .catch((err) => {return false;});
      return data;
    }catch(error){
      this.manageError(error);
      return false;
    }
  }

  async getCrmConfigData(ll_hash) {
    try{
     let session = ls.get("session");
      if(!axios.defaults.headers.common['Authorization'] && session){
        axios.defaults.headers.common['Authorization'] = session;
        document.cookie = "PHPSESSID="+session;
      }
      
      var data = {};
      let url = LLAPIPROCESS+'get-crm-config'
      if(!ll_hash){
        return false;
      }
      
      data = await axios
        .post(url, { 'X-LL-Hash': ll_hash, 'products' : {} })
        .then((response) =>{
          if (response.status === 200) {
            return response.data;
          }
        })
        .catch((err) => {return false;});
    }catch(error){
      this.manageError(error);
      return false;
    }
    return data;
  }

  getTokenUserPayments() {
    try{

     let session = ls.get("session");
      if(!axios.defaults.headers.common['Authorization'] && session){
        axios.defaults.headers.common['Authorization'] = session;
        document.cookie = "PHPSESSID="+session;
      }
      
      var data = {};
      let url = LLAPIPROCESS+'get-token-payments';
      let ll_hash = this.getllHashAtLocal();
      if(!ll_hash){
        return false;
      }
      const tokenUserPayments = ls.get('tokenUserPayments')
      
      if(tokenUserPayments && tokenUserPayments.payments){
        return tokenUserPayments;
      }
      
      data = axios
              .post(url, { 'X-LL-Hash': ll_hash , 'token_user_payments': true})
              .then((response) =>{
                if (response.status === 200) {
                  let l = ls.set("tokenUserPayments", response.data);
                  if(l){              
                    return response.data;
                  }
                }
              })
              .catch((err) => {ls.remove("tokenUserPayments");return false;});
      return data;
    }catch(error){
      this.manageError(error);
      return false;
    }
  }

  async calculateTax() {
    try{


      var data = {};
      let url = LLAPIPROCESS+'process-order-total';
      let ll_hash = this.getllHashAtLocal();
      if(!ll_hash){
        return false;
      }
      
      const cartInfo = await this.getCartDataAtLocal();
      
      let customerInfo = ls.get('customerInfo')
      let shippingId = customerInfo && customerInfo.shipping ? customerInfo.shipping : "";
      let country = customerInfo && customerInfo.country ? customerInfo.country : "";
      let state = customerInfo && customerInfo.state ? customerInfo.state : "";
      let promo_code = (ls.get('coupon')) ? ls.get('coupon') : "";
      let email = customerInfo && customerInfo.email ? customerInfo.email : "";
      let zip = customerInfo && customerInfo.zip ? customerInfo.zip : "";
      let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;

      
      shippingId = shippingId === "" && cartInfo && cartInfo.cartInfo && cartInfo.cartInfo.shippingMethods && Array.isArray(cartInfo.cartInfo.shippingMethods) && cartInfo.cartInfo.shippingMethods.length>0 ? cartInfo.cartInfo.shippingMethods[0]['shipping_method_crm_id']  : shippingId;
      
 
      if(!shippingId && cartInfo.cartInfo.token_based_user_free_shipping){
        // if no shipping found and token based user free shipping is true
        shippingId = ls.get('defaultShipping');
      }
      
      if(cartInfo.cartInfo.hasProspect && customerInfo){
        shippingId = shippingId ? shippingId : ls.get('defaultShipping');
        customerInfo.shipping=shippingId;
        ls.set('customerInfo',customerInfo);
      }
      
      let products
      if(cartInfo && cartInfo.cartInfo){
        if(cartInfo.cartInfo.shopDetails.label_url === ll_hash || ll_hash.indexOf(cartInfo.cartInfo.shopDetails.label_url)>-1){
          products = cartInfo.cartInfo.shopDetails.products
        }else{
          products = {}
        }   
      }

      let oneClickUpsells = ls.get("oneClickUpsells");

      const postData = {
        'promo_code' : email === "" ? "":promo_code,
        'X-LL-Hash': this.getCheckoutHash(),
        "shippingId" : shippingId,
        "country" : country,
        "state" : state,
        "email" : email,
        "products" : products,
        "zip":zip,
        "removed_items" : (removed_items !== false) ? Object.assign({},removed_items) : {},
        "oneClickUpsells":oneClickUpsells
      }

      data =await axios
          .post(url,postData)
        .then((response) =>{
          if (response.status === 200) {
            if(response.data.responseData.status === "SUCCESS"){
              ls.set("tax", response.data.responseData.data);

              //for discount
            promo_code = promo_code!=="" ?  promo_code.toString().toUpperCase() : "";
            let discounts = 'discounts' in response.data.responseData.data && Object.keys(response.data.responseData.data.discounts).length>0 ? response.data.responseData.data.discounts.find(ele=>ele.type.toString() === "promo") : {};
            if(discounts && 'valid' in discounts && discounts.valid && 'total' in discounts && promo_code === discounts.code.toString().toUpperCase()){
              response.data.responseData.data.discount_amount = discounts.total;
              ls.set("discount", discounts.total.toString().replace(/,/g, ''));
              ls.set("coupon_valid",true);
            }
            else
            {
            }
              return response.data.responseData.data;
            }else{
              ls.remove("discount");
              ls.set("tax", false);
              return false;
            }
              
          }
        })
        .catch((err) => {ls.remove("discount");ls.remove("tax");return false;});

      await this.getCheckoutBasedShippingConfig()
     
      return data;
    }catch(error){
      console.log("heree1", error);
      this.manageError(error);
      ls.remove("discount");
      return false;
    }
  }

  async getCartDataAtLocal(){
    let data = await store.getState().llConfig.cartData;
    return {'cartInfo':data};
  }

  getllHashAtLocal(){
    let data = store.getState().llConfig.llHash;
    return data;
  }
  
  async saveProspect(inputs) {
    try{
        inputs = this.validateObjForSpace(inputs);
        ls.set("customerInfo", inputs);
        let url = LLAPIPROCESS+'create-prospect';
        inputs['X-LL-Hash'] = this.getllHashAtLocal();
        let prospectId = ls.get('prospectId');

        if(prospectId){
          inputs['prospect_id'] = prospectId;
        }
        //add affiliate value 
        let affilateKeys = this.getAffilateKeyAndVal();
        
        if(affilateKeys){
          inputs = {...inputs, ...affilateKeys};
        }
        
        
        let res = await axios.post(url, inputs);

        if (res.data.status === true) {

          let prospectId = ls.get('prospectId') ;
          
          if(!prospectId){
            ls.set("prospectId", res.data.responseData.prospectId)
          }

          let smile = res && res.data && res.data.smile_io  ? res.data.smile_io : {}; 
          ls.set('smile_io',smile);
          
          return true;
        }
        return false;
    }catch(error){
      console.log(error);
      this.manageError(error);
      return false;
    }
   
  }

  saveShipping(inputs) {
   
    let info = ls.get("customerInfo");
    if(info && inputs){
      info.shipping = inputs.shipping;
      ls.set("customerInfo", info);
      return true;
    }
   return false;
   
  }

  getProspect() {
    let emptyProspect = {
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      pincode: "",
      phone: "",
      shipping: "",
      address1 : "",
      zip : ""
    };
    return ls("customerInfo") ? ls.get("customerInfo") : emptyProspect;
  }

  async getUpsells(inputs){
    let url = LLAPIPROCESS+'get-upsells';
    if(!inputs){
      inputs ={
        'X-LL-Hash' : this.getllHashAtLocal()
      }
    }else{
      inputs['X-LL-Hash'] = this.getllHashAtLocal();
    }
    let res = await axios.post(url, inputs);

    if (res.data.status === true) {
      ls.set("upsells", res.data);
      return true;
    }
    return false;
  }

  getUpsellDetail(){
    return ls.get("upsells");

  }

  async processUpsell(param){
    try{
      let url = LLAPIPROCESS+'process-upsell-order';
      let prospect = await this.getProspect();
       let upsellparam ={
        previousOrderId: ls.get("orderId"),
        ll_product: param._id,
        upsell_id:param.upsell_id,
        upsell_label:param.upsell_label,
        'X-LL-Hash' : this.getllHashAtLocal(),
        'shippingId' : (prospect && prospect.shipping) ? prospect.shipping :''
      }
      // process-upsell-order
      //Add 
      let paypalResponse = ls.get("paypalResponse");
      if(paypalResponse){
        upsellparam.transaction_reference = (paypalResponse && paypalResponse.transactionID) ? paypalResponse.transactionID : "";
        upsellparam.alt_pay_payer_id = (paypalResponse && paypalResponse['subscription_id[1]']) ? paypalResponse['subscription_id[1]'] : "";
        upsellparam.creditCardType="paypal";
        upsellparam.alt_pay_token = (paypalResponse && paypalResponse.token) ? paypalResponse.token : "" 
        upsellparam.tranType="sale";
      }

      //upsell

      //Amazon pay
      let amazonPay = ls.get("amazonPay");

      if(amazonPay && 'amazonBillingAgreementId' in amazonPay && amazonPay.amazonBillingAgreementId){
        upsellparam.billing_agreement_id = amazonPay.amazonBillingAgreementId;
      }
      

      let res = await axios.post(url, upsellparam);
      
      if (res.data.status === true) {
        this.saveProcessUpsell(param);
        return true;
      }

      return false;

    }catch(error){
      this.manageError(error);
    }
   
  }

  getOrderConfirmId(){
    return ls.get("orderId");
  }
async manageLLHash(param){
  //save the ll hash to localhost
  try{
    
    let ll_hash = param && param.llHash? param.llHash : '';
    let storage_ll_hash = this.getllHashAtLocal();
    if(ll_hash && ll_hash!=='' && ll_hash!==storage_ll_hash){
      store.dispatch(setLlHash(ll_hash));
    }
    
  }catch(e){
    console.log("Error",e);
  }

}
async validateOrderPage(){
  let order_id = ls.get("orderId");
  if(order_id){
    await this.getUpsells("");
    return true;
  }

  return false;
}
async processOrder(inputs){ 
  try{
        inputs = this.validateObjForSpace(inputs);
        let endPoint = inputs && inputs.endPoint ? inputs.endPoint : 'process-order';
        let oneClickUpsells = ls.get("oneClickUpsells");
        let giftItems = ls.get('giftItems');
        let taxDetail = ls.get("tax");
        let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;
        inputs["tax"] = taxDetail && taxDetail.tax && taxDetail.tax.total ? parseFloat(taxDetail.tax.total,10).toFixed(2) : 0;
        inputs["tax_pct"] = taxDetail && taxDetail.tax && taxDetail.tax.pct ? parseFloat(taxDetail.tax.pct,10).toFixed(2) : 0; 

        let url = LLAPIPROCESS+endPoint;
        inputs['X-LL-Hash'] = this.getCheckoutHash();
        inputs['prospectId'] = ls.get("prospectId");
        inputs['ll_product'] = ls.get("ll_product");
        inputs['previousOrderId'] = ls.get("previousOrderId");
        inputs['oneClickUpsells'] = oneClickUpsells;
        inputs['removed_items'] = (removed_items !== false) ? Object.assign({},removed_items) : {};

        if(giftItems && giftItems !== false && Object.keys(giftItems).length > 0){
          // Check in case of oneClickUpsells null
          if(inputs['oneClickUpsells'] === null){
            inputs['oneClickUpsells'] = [];
          }
          // Set GiftItems into oneClickUpsells object
          Object.keys(giftItems).forEach((key) => {
            inputs['oneClickUpsells'].push(giftItems[key]);
          });
        }

        let coupon = ls.get('coupon');

        if(coupon){
          inputs['promoCode'] = coupon;
        }
        let discount = ls.get("discount");

        if(discount){
          inputs['discount'] = discount;
        }
        
        await this.createACinShopify("order","");
        if(inputs.custom_fields && inputs.payment_page_custom_fields){
          // if both places custom fields are there
          inputs.custom_fields = {...inputs.custom_fields, ...inputs.payment_page_custom_fields};
        }else if(!inputs.custom_fields && inputs.payment_page_custom_fields){
          inputs['custom_fields'] = inputs.payment_page_custom_fields;
        }
        const cartInfo = await this.getCartDataAtLocal();
        inputs['products'] = (cartInfo && cartInfo.cartInfo) ? cartInfo.cartInfo.shopDetails.products : {}
        
        if(!inputs['shippingId'] && cartInfo.cartInfo.token_based_user_free_shipping){
          // if no shipping found and token based user free shipping is true
          inputs['shippingId'] = ls.get('defaultShipping');
        }
        
        let res = await axios.post(url, inputs);
        if (res.data.status === true) {
          ls.set("orderId", res.data.responseData.order_id)
          return true;
        }else{
          ls.remove("discount");
        }
        return false;
  }catch(error){
    ls.remove("discount");
    this.manageError(error);
    return null;
  }
}

removeLocalStorageVal(){
  ls.remove('orderId');
  ls.remove('prospectId');
  ls.remove('upsells');
  ls.remove('upsellIndex');
  ls.remove('processUpsell');
  ls.remove('discount');
  ls.remove('coupon');
  ls.remove('coupon_valid');
  let customerInfo = ls.get('customerInfo')
  let ship = customerInfo && customerInfo.shipping ? customerInfo.shipping :"";
  ls.remove('customerInfo')
  ls.remove('paypalResponse');
  ls.remove('ordertotal');
  ls.remove("nonce");
  ls.remove('billingAddress')
  ls.remove('amazonPay');
  ls.remove('oneClickUpsells');
  ls.remove('orderDetails');
  ls.remove('redeem_coupons_with_amount');
  ls.remove('redeemCoupons');
  ls.remove('smile_io');
  ls.remove('removed_items');
  ls.remove('removed_item_ll_hash');
  ls.remove('tokenUserPayments');
  ls.remove('previousOrderId');
  
  let prospectObject = {
      email: "",
      firstName: "",
      lastName: "",
      address: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      pincode: "",
      shipping : ship,
      phone: "",
      address1 : "",
      zip : ""
    };
  ls.set('customerInfo', prospectObject);
}


async applyDiscountCode(discountCode){
  try{
    let prospect = await this.getProspect();
    let url = LLAPIPROCESS+'process-coupon';
    let customerInfo = ls.get('customerInfo');

    let inputs ={
      'X-LL-Hash':this.getllHashAtLocal(),
      'promo_code':discountCode,
      'email': customerInfo && customerInfo.email? customerInfo.email:'',
      'shippingId' : (prospect && prospect.shipping) ? prospect.shipping : ""
    }; 
  
    const cartInfo = await this.getCartDataAtLocal();
    inputs['products'] = (cartInfo && cartInfo.cartInfo) ? cartInfo.cartInfo.shopDetails.products : {}

    let res = await axios.post(url, inputs);
    if (res.data.status === true) {
      ls.set("discount", res.data.responseData.coupon_amount);
      ls.set('coupon',discountCode);
      return res.data.responseData.coupon_amount;
    }
    return false;
  }catch(e){
    this.manageError(e);
    return false;
  }
  

}

getDiscountDetail(){
  return {discount: ls.get('discount') ? ls.get('discount') : '0.00' ,coupon:ls.get('coupon'),coupon_valid:ls.get('coupon_valid')};
}

removeDiscountCode(){
  ls.remove('discount');
  ls.remove('coupon');
  ls.remove('coupon_valid');
  return true

}


// --------------------------- Custom Error -----------------
async manageError(error){
  try{

    //
    if(error && error.response && error.response.data && error.response.data.exception){
      this.sweetError(error.response.data.exception[0]['message']);
      return false;
    }
    //For Multiple error
    if(error && error.response && error.response.data.message.indexOf(':,:')>-1){
      let errArr = error.response.data.message.split(':,:');
  
      await errArr.forEach(function(value){
  
        this.sweetError(value);
      });
      return true;
    }

    

    this.sweetError(error.response.data.message);
  }catch(e){
    this.sweetError("Something went wrong, Please try again later");
  }
 
}

sweetError(msg){

  if(msg){
    swal({
      title: "OOPS!",
      text: msg,
      icon: "error",
    });
  }
 
}

async getSelectedProduct(){
  try{
    let product = ls.get("ll_product");
    let cartInfo = await this.getCartDataAtLocal();
    if(product && cartInfo){
      ls.set('selectedProduct',cartInfo.cartInfo.shopDetails.products[product]);
      return cartInfo.cartInfo.shopDetails.products[product];
    }
  }catch(e){
    return false;
  }
}

getHash(){
  return this.getllHashAtLocal();
}


async setUpsellIndex(validIndex){
  try{
    if(!validIndex && validIndex!==0){
      return false;
    }
    ls.set('upsellIndex',validIndex);
  }catch(e){
    return false;
  }
}


async getUpsellIndex(){
  try{
    let ind = ls.get('upsellIndex');
    return ind;
  }catch(e){
    return false;
  }
}

async saveProcessUpsell(param){
  try{

    if(!param){
      return false;
    }
    
    let savedUpsell = ls.get("processUpsell");

    if(!savedUpsell){
      let temData = [];
      temData[0] = param;
      savedUpsell = temData;
    }else{
      savedUpsell.push(param);
    }

  
    ls.set('processUpsell',savedUpsell);
    return true;
  }catch(e){
    return false;
  }
}

getProcessUpsellData(){
  return ls.get('processUpsell');
}

async processPayPalOrder(inputs){
  try{
    inputs['url'] = LLAPIPROCESS+'process-onepage-checkout';
    inputs['X-LL-Hash'] = this.getllHashAtLocal();
    inputs['alt_pay_return_url'] = window.location.origin +'/paypal-success';

    let taxDetail = ls.get("tax");
    inputs["tax"] = taxDetail && taxDetail.tax && taxDetail.tax.total ? parseFloat(taxDetail.tax.total,10).toFixed(2) : 0;
    inputs["tax_pct"] = taxDetail && taxDetail.tax && taxDetail.tax.pct ? parseFloat(taxDetail.tax.pct,10).toFixed(2) : 0; 
    
    let coupon = ls.get('coupon');

    if(coupon){
      inputs['promoCode'] = coupon;
    }

    let oneClickUpsells = ls.get("oneClickUpsells");
    inputs['oneClickUpsells'] = oneClickUpsells;
    let giftItems = ls.get('giftItems');
    if(giftItems && giftItems !== false && Object.keys(giftItems).length > 0){
      // Check in case of oneClickUpsells null
      if(inputs['oneClickUpsells'] === null){
        inputs['oneClickUpsells'] = [];
      }
      // Set GiftItems into oneClickUpsells object
      Object.keys(giftItems).forEach((key) => {
        inputs['oneClickUpsells'].push(giftItems[key]);
      });
    }

    let discount = ls.get("discount");

    if(discount){
      inputs['discount'] = discount;
    }
    
    const cartInfo = await this.getCartDataAtLocal();
    inputs['products'] = (cartInfo && cartInfo.cartInfo) ? cartInfo.cartInfo.shopDetails.products : {}

    await this.createACinShopify("order","");
    
    return inputs;
  }catch(error){
    this.manageError(error);
    return false;
  }
}

async savePaypalResponse(data){
  try{
    ls.set('paypalResponse',data);

    if(data && data.errorFound<1){
      ls.set("orderId", data.orderId)
    }
    
  }catch(e){
    return false;
  }
}

 getPaypalResponse(){
  try{
    return ls.get('paypalResponse');
  }catch(e){
    return false;
  }
}

async saveSquareNonce(nonce){
  try{
    ls.set("nonce",nonce);
    return ls.get("nonce");
  }catch(e){
    //error
  }
}

async getSquareNonce(nonce){
  try{
    return ls.get("nonce");
  }catch(e){
    //error
  }
}

async removeNonce(){
  try{
    return ls.remove("nonce");
  }catch(e){
    //error
  }
}

async updateOrdertotal(orderTotal){
  try{
    ls.set("ordertotal",orderTotal);

  }catch(e){
    //
  }
}

async getOrderTotal(){
  try{
    return ls.get("ordertotal");
  }catch(e){
    return false;
  }
}

async updateCartInfo(customerInfo){
  try{
    if(!customerInfo){
      return 0;
    }

    ls.set("customerInfo",customerInfo);
    return ls.get("customerInfo");
  }catch(e){
    console.log("Error",e);
  }
}

async manageSession() {
  try{
    let data = {};
    let url = LLAPIPROCESS+'session';
    data = axios
      .get(url,{data:{}}, {headers:{withCredentials: true}})
      .then((response) =>{
        if(response && response.data.length > 0){
          ls.set("session",response.data[0]);
          document.cookie = "PHPSESSID="+response.data[0];
        }
      })
      .catch((err) => {return false;});
    return data;
  }catch(e){
    console.log(e)
  }
}

async processOrderForShopify(inputs){
  try{
    let url = LLAPIPROCESS+'back-sync-paypal-order';
    let taxDetail = ls.get("tax");
    inputs["tax"] = taxDetail && taxDetail.tax && taxDetail.tax.total ? parseFloat(taxDetail.tax.total,10).toFixed(2) : 0;
    inputs["tax_pct"] = taxDetail && taxDetail.tax && taxDetail.tax.pct ? parseFloat(taxDetail.tax.pct,10).toFixed(2) : 0;

    let res = await axios.post(url, inputs);
        if (res.data.status === true) {
          
          return true;
        }
      return false;
  }catch(e){
    return false;
  }
}

async getSession(){
  try{
    return ls.get("session");
  }catch(e){
    return false;
  }
}

async applyDiscountCodeWithOrderTotal(discountCode,showPopup=true){ 
  try{

    if(!discountCode){
      this.sweetError("Please enter a valid coupon");
      return false;
    }

    var data = {};
    let url = LLAPIPROCESS+'process-order-total';
    let ll_hash = this.getllHashAtLocal();
    if(!ll_hash){
      return false;
    }
    const cartInfo = await this.getCartDataAtLocal();
    
    let customerInfo = ls.get('customerInfo')
    let shippingId = customerInfo && customerInfo.shipping ? customerInfo.shipping : "";
    let country = customerInfo && customerInfo.country ? customerInfo.country : "";
    let state = customerInfo && customerInfo.state ? customerInfo.state : "";
    let promo_code = discountCode;
    let email = customerInfo && customerInfo.email ? customerInfo.email : "";
    let zip = customerInfo && customerInfo.zip ? customerInfo.zip : "";
    let removed_items = (ls.get('removed_items')) ? ls.get('removed_items') : false;
    
    let products
    if(cartInfo && cartInfo.cartInfo){
      if(cartInfo.cartInfo.shopDetails.label_url === ll_hash || ll_hash.indexOf(cartInfo.cartInfo.shopDetails.label_url)>-1){
        products = cartInfo.cartInfo.shopDetails.products
      }else{
        products = {}
      }   
    }
    
    if(!shippingId && cartInfo.cartInfo.token_based_user_free_shipping){
      // if no shipping found and token based user free shipping is true
      shippingId = ls.get('defaultShipping');
    }

    if(!shippingId){
      if(showPopup)
        this.sweetError("Please select a valid shipping method");
      return false;
    }


    if(!email){
      if(showPopup)
        this.sweetError("Please enter a valid prospect email");
      return false;
    }
    promo_code = promo_code.toString().toUpperCase();
    let oneClickUpsells = ls.get("oneClickUpsells");
    const postData = {
      'promo_code' : promo_code,
      'X-LL-Hash': ll_hash,
      "shippingId" : shippingId,
      "country" : country,
      "state" : state,
      "email" : email,
      "products" : products,
      "zip":zip,
      "removed_items" : (removed_items !== false) ? Object.assign({},removed_items) : {},
      "oneClickUpsells":oneClickUpsells
    }

    data = axios
        .post(url,postData)
      .then((response) =>{
        if (response.status === 200) {
          if(response.data.responseData.status === "SUCCESS"){

            let discounts = 'discounts' in response.data.responseData.data && Object.keys(response.data.responseData.data.discounts).length>0 ? response.data.responseData.data.discounts.find(ele=>ele.type.toString() === "promo") : {};
            if(discounts && 'valid' in discounts && discounts.valid && 'total' in discounts && promo_code === discounts.code.toString().toUpperCase()){
              response.data.responseData.data.discount_amount = discounts.total;
              ls.set("discount", discounts.total.toString().replace(/,/g, ''));
              ls.set('coupon',discountCode);
            }else{
              if(showPopup)
                this.sweetError("Coupon Error: Invalid Promo Code");
              return false;
            }
            return response.data.responseData.data;
          }else{
            return false;
          }
            
        }
      })
      .catch((err) => {ls.remove("discount");ls.remove("tax");return false;});
    return data;
  }catch(error){
    this.manageError(error);
    ls.remove("discount");
    return false;
  }
}

async getOrderDetail(orderId){
  try{
    let url = LLAPIPROCESS+'order-detail'
    let ll_hash = this.getllHashAtLocal();

    if(!ll_hash || !orderId){
      return false;
    }

    const postData = {
      'X-LL-Hash': ll_hash,
      'order_id' : orderId
    }

    return axios
        .post(url,postData)
        .then((response) =>{
            let orderDetail = false;
            if (response.status === 200 && response.data.responseData) {
              orderDetail= response.data.responseData;
            }else{
              orderDetail= false;
            }
            ls.set("orderDetails",orderDetail);
            return orderDetail

      })
      .catch((err) => {return false;});

  }catch(e){
    console.log("Error :",e);
    return false;
  }
}

async getAmazonPayNonce(){
  return ls.get("amazonPay");
}

getAffilateKeyAndVal(){
  try{
    var result = {};
    let queryStr = ls.get("affiliate")
    if(!queryStr) return result;
    
    if(Object.keys(queryStr).length<1)  return result;

    for(var key in queryStr){
      if(key !== 'client_id'){
        result[key.toUpperCase()] = queryStr[key];
      }
    }
    
    return result;
  }catch(e){
    console.log("Error",e);
    return {};
  }
}

async getCheckoutBasedShippingConfig(){
  try{
        let customerInfo = ls.get("customerInfo");
        let shipping = customerInfo && customerInfo.shipping ? customerInfo.shipping : "";
        let orderTotal = ls.get("ordertotal");
        let url = LLAPIPROCESS+'get-shipping-methods-from-configuration'
        let cartInfo = await this.getCartDataAtLocal();
        let checkout_shipping_config = cartInfo && cartInfo.cartInfo && cartInfo.cartInfo.checkout_shipping_config ? cartInfo.cartInfo.checkout_shipping_config: false;
        let shippingMethods = cartInfo && cartInfo.cartInfo && cartInfo.cartInfo.shippingMethods ?cartInfo.cartInfo.shippingMethods :[];
        let foundShipping = false;
        let shippingOnCheckoutConfig = ls.get("shippingOnCheckoutConfig");
        if(checkout_shipping_config === false){
          
          if(shippingOnCheckoutConfig && shippingOnCheckoutConfig<1){
            ls.remove("shippingOnCheckoutConfig"); 
            for(let i=0;i<shippingMethods.length;i++){
              if(customerInfo.shipping === shippingMethods[i]['shipping_method_crm_id']){
                foundShipping = true
              }
            }
  
            if(foundShipping=== false){
              customerInfo.shipping = "";
              ls.set("customerInfo",customerInfo);
            }
          }

          return false;
        }
        
        let inputs = {};
        inputs['X-LL-Hash'] = this.getllHashAtLocal();
        inputs['country'] = customerInfo && customerInfo.country ? customerInfo.country : "";
        inputs['zip'] = customerInfo && customerInfo.zip ? customerInfo.zip : "";
        inputs['orderTotal'] = orderTotal ? orderTotal : 0;
        inputs['state'] = ls.get("selectedState");
        inputs['city'] = customerInfo && customerInfo.city ? customerInfo.city : "";

        let res = await axios.post(url, inputs);
        
        if(res && res.data && res.data.length>0){
          ls.set("shippingOnCheckoutConfig",res.data);
          
          for(let i=0;i<res.data.length;i++){
            if(shipping === res.data[i]['shipping_method_crm_id']){
              foundShipping = true
            }
          }

          if(foundShipping === false){
            customerInfo.shipping = "";
            ls.set("customerInfo",customerInfo);
          }
        }else{
          ls.remove("shippingOnCheckoutConfig"); 

          for(let i=0;i<shippingMethods.length;i++){
            if(customerInfo.shipping === shippingMethods[i]['shipping_method_crm_id']){
              foundShipping = true
            }
          }

          if(foundShipping === false){
            customerInfo.shipping = "";
            ls.set("customerInfo",customerInfo);
          }
        }
        
      }catch(e){
        return false
      }
    } 
        
async validateCustomer(email){
  try{
    
    let url = LLAPIPROCESS+'validate-shopify-customer';

    let res = await axios.post(url, {email:email,'X-LL-Hash':this.getllHashAtLocal()});
    if (res.data.status === true) {
      return true;
    }

    return false;
  }catch(e){
    return false
  }
}

async createCustomerProfile(password){
  try{
    let prospect = ls.get('customerInfo');
    
    if(!prospect){
      swal({
        title: "OOPS!",
        text: "Invalid prospect, Please update your email and shipping detail",
        icon: "error",
      });
      return false;
    }

    prospect['X-LL-Hash'] = this.getllHashAtLocal();
    prospect['password'] = password;
    
    let url = LLAPIPROCESS+'create-shopify-customer';

    let res = await axios.post(url,prospect);
    
    if (res.data.status === true) {
      return true;
    }

    return false;
  }catch(e){
    return false;
  }
}


async createACinShopify(routeName,emailParam){
  let cartInfo = await this.getCartDataAtLocal();
  let checkout_enable_shopify_profile_creation = cartInfo.cartInfo && 'checkout_enable_shopify_profile_creation' in cartInfo.cartInfo && cartInfo.cartInfo.checkout_enable_shopify_profile_creation ? cartInfo.cartInfo.checkout_enable_shopify_profile_creation : false;
  let account_created_after_order_status = cartInfo.cartInfo && 'account_created_after_order_status' in cartInfo.cartInfo && cartInfo.cartInfo.account_created_after_order_status ? cartInfo.cartInfo.account_created_after_order_status : false;
var thisRef = this;
  return new Promise(async function(resolve, reject) {
    try{
      if(checkout_enable_shopify_profile_creation!==true){
        return resolve(true);
      }
     
      if(account_created_after_order_status === true && routeName ==="prospect"){
        return resolve(true);
      }

      let prospect = ls.get("customerInfo");
      let email = prospect && prospect.email ? prospect.email :emailParam;
      let userAccount = await thisRef.validateCustomer(email);

      if(userAccount!==true){
        
        let password = prospect && prospect.password;
        await thisRef.createCustomerProfile(password);
        return resolve(true);
      }else{
        return resolve(true);
      }
    }catch(e){
      resolve(true);
    }
  });
}

getOrderDetailFromLocal(){
  return ls.get("orderDetails");
}

setEcllipseOnString(str, length){
  if(str.length > 24){
    return str.substr(0, length) + '...';
  }
  return str;
}
async getRedeemCouponDiscount(){
    try{
        
        let url = LLAPIPROCESS+'validate-redeem-discount';
        let res = await axios.post(url,{'X-LL-Hash': this.getllHashAtLocal()});
        let redeem_coupons = res && res.data && res.data.redeem_coupons ? res && res.data && res.data.redeem_coupons : [];
        ls.set("redeem_coupons_with_amount",redeem_coupons);
        return redeem_coupons;
  
    }catch(e){
      console.log("Error",e);
      return false;
    }
}

getremovedItems(configData){
  try{
    let removed_items = (ls.get("removed_items") !== false && ls.get("removed_items").length > 0) ? ls.get("removed_items") : false;
    if(configData && (removed_items !== false && removed_items.length > 0)){
      configData.removed_items = Object.assign({},removed_items);
    }
    return configData;
  }catch(e){
    return configData;
  }
}

getCheckoutHash(){
  try{
    let ll_hash = this.getllHashAtLocal();
    var params = {}, queries, temp, i, l;
    queries = ll_hash.split("&");
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      if (temp.length < 2) {
        params[i] = temp[i];
        continue;
      }
      params[temp[0]] = temp[1].split(',');
    }
    if(params && params.slug){
      return (ls.get('removed_item_ll_hash')) ? ls.get('removed_item_ll_hash') : this.getllHashAtLocal();
    }
    return this.getllHashAtLocal();
  }catch(e){
    return this.getllHashAtLocal();
  }
}

checkProcessForPaymentorNot(){
  try {
      // Check if all item removed from cart
      let productTable = document.querySelectorAll('.prod-items-list tr.product');
      if(productTable.length === 0){
        return false;
      }
      return true;  
  } catch (error) {
    return false;
  }
}
validateSpaceForInput(string, betweenSpaceStat = true){
  try {
    let resultStr = string || "";
    // Removed head and tail spaces
    resultStr = resultStr.trim();
    // Check for Conditional multiple space to one space
    if(betweenSpaceStat){
      // Replace multiple spaces to one space
      resultStr = resultStr.replace(/  +/g,' ');
      return resultStr;
    }
    return resultStr;
  } catch (error) {
    console.log(error)
    return string;
  }
}

validateObjForSpace(data){
  let inputData = data;
  try {
    Object.keys(inputData).forEach((key) => {
       // Key can be update in array if need to update multiple space to one space
       if([ 'cc_holder_name', 'billingFirstName', 'billingLastName', 'billingCity', 'billingAddress1', 'billingAddress2','firstName', 'lastName', 'address1', 'address2', 'city'].indexOf(key) > -1){
        inputData[key] = this.validateSpaceForInput(inputData[key], true);
       }else{
        inputData[key] = this.validateSpaceForInput(inputData[key], false); 
       } 
    });
    return inputData;
  } catch (error) {
    console.log(error)
    return inputData;    
  }
}
getCardType(number)
{
    // visa
    var re = new RegExp("^4");
    if (number.match(re) !== null)
        return "visa";

    // Mastercard 
    // Updated for Mastercard 2017 BINs expansion
     if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
        return "master";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) !== null)
        return "amex";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) !== null)
        return "discover";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) !== null)
        return "diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) !== null)
        return "diners";


    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) !== null)
        return "visa";

    return "visa";
}

updateCssObject(custom_css, checkout_font) {
  // console.log(custom_css)
  let style = "body { "
  if (custom_css && Object.keys(custom_css).length > 0) {
    Object.keys(custom_css).forEach((key) => {
      style = style + ' --' + key + ' : ' + custom_css[key] + ' !important;'
    })
  }
  // style = style + ' } html {-webkit-filter: invert(100%);' +
  // '-moz-filter: invert(100%);' + 
  // '-o-filter: invert(100%);' + 
  // '-ms-filter: invert(100%); }'
  if (checkout_font && checkout_font !== "") {
    style = style + "font-family : " + checkout_font + " !important;"
  }
  style = style + ' }'
  return style
}

})();
