import { useLocation } from 'react-router-dom'
import { useEffect } from 'react';
import commonHooks from './commonHooks';
import Checkout from './checkout';


export const NavigationHook = () => {
    let pathname = useLocation().pathname;
    var commonHooksIns = new commonHooks();
    useEffect(() => { 
        var res = commonHooksIns.routeHooks();
        res.then(async ()=>{
            if (pathname === '/thank-you') {

                var intervalIns = setInterval(function(){ 
                    var orderDetail = Checkout.getOrderDetailFromLocal();
                    if(orderDetail){
                        clearInterval(intervalIns);
                        Checkout.removeLocalStorageVal();

                    }
                 }, 1000)
            }
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [pathname])
    return '';
};