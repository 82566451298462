import axios from "axios";

export default new (class Places {
  constructor() {
    this.countries = {};
  }

  async getCountries(countryList, searchArr = []) {
    var response = countryList;
    if (searchArr.length > 0) {
      let list = [];
      searchArr.forEach((s,index) => {

        list.push(
          response.find(countryData => (countryData && countryData.code2 === searchArr[index]))
        );
      });
      list = this.sortByKey(list,'name');
      return list;
    }
    response = this.sortByKey(response,'name');
    return response;
  }
  
  sortByKey(t, r) {
      return t.sort(function (t, e) {
          return "string" === typeof t[r] && ((t = t[r].toLowerCase()), (e = e[r].toLowerCase())) && t < e ? -1 : t > e ? 1 : 0;
      });
  }

  async getStates(country) {
    let matchedCountry = await axios
      .get(window.location.origin + "/json/countries.json")
      .then(response => {
        return response.data.find(countryData => countryData.code2 === country);
      })
      .catch(err => console.log(err));
    return matchedCountry && matchedCountry.hasOwnProperty("states")
      ?  this.sortByKey(matchedCountry.states,'name')
      : [];
  }

  async getStatesviaProps(countryList, country) {
    
      let matchedCountry =  countryList.find(countryData => countryData && 'code2' in countryData && countryData.code2 === country);
      return matchedCountry && matchedCountry.hasOwnProperty("states") ? this.sortByKey(matchedCountry.states,'name') : [];
  }

  async getStatesForThankPage(countryList, country) {
    let matchedCountry = countryList.find(countryData => countryData.code2 === country);
    return matchedCountry
  }
})();
