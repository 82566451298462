import Checkout from "./checkout"
import ReactGA from 'react-ga';

class google{
    constructor(){
        //
        this.initAction = this.initAction.bind(this);
        this.google_id="";
        this.checkout_enable_google_analytics = false;
        this.initAction();
    }

    async initAction(){
        try{
            let cartInfo = await Checkout.getCartDataAtLocal();
            this.google_id = cartInfo && cartInfo.cartInfo && cartInfo.cartInfo.google_id ? cartInfo.cartInfo.google_id : false;
            this.checkout_enable_google_analytics = cartInfo && cartInfo.cartInfo && cartInfo.cartInfo.checkout_enable_google_analytics ? cartInfo.cartInfo.checkout_enable_google_analytics : false;
            
            if(this.google_id && this.checkout_enable_google_analytics !== false){
                ReactGA.initialize(this.google_id);
            } 
        }catch(e){
            console.log("Error while init",e);
        }
    }

    async beginProcess(event){
        try{

            if(this.checkout_enable_google_analytics === false){
                return false;
            }

            if(!event || !this.google_id){
                return false;
            }
            
           await ReactGA.ga('send',event, window.location.pathname);
           return true;
        }catch(e){
            return false;
        }
    }

}

const googleInstance = new google();
export default googleInstance;
